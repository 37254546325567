import { Row, Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFacebook } 	from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faXTwitter } 	from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faInstagram } 	from '@fortawesome/free-brands-svg-icons/faInstagram';

import { faYoutube } 	from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faSpotify } 	from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faApple } 		from '@fortawesome/free-brands-svg-icons/faApple';

import '../styles/Footer.css'

const socials = [
	{
		link: "https://facebook.com/coheedandcambria",
		icon: faFacebook,
		from: 'fontawesome'
	},
	{
		link: "https://twitter.com/coheed",
		icon: faXTwitter,
		from: 'fontawesome'
	},
	{
		link: "https://instagram.com/coheed",
		icon: faInstagram,
		from: 'fontawesome'
	},
	{
		link: "https://www.youtube.com/coheedandcambriaofficial",
		icon: faYoutube,
		from: 'fontawesome'
	},
	{
		link: "https://open.spotify.com/artist/3utxjLheHaVEd9bPjQRsy8?si=U4wUnd2ySMa9p5mGSfm3HQ",
		icon: faSpotify,
		from: 'fontawesome'
	},
	{
		link: "https://music.apple.com/us/artist/coheed-and-cambria/15031628",
		icon: faApple,
		from: 'fontawesome'
	}
]

const Footer = () => {
	return (
		<Row className="footer">
			<Col className="text-center mx-auto">
				<div className="d-flex justify-content-around mt-2 p-5 pt-0 socialLinks flex-wrap">
					{socials.map((social, idx) => (
						<div key={idx}>
							<a href={social.link} target="_blank" className="d-block text-white" rel="noreferrer">
								<FontAwesomeIcon icon={social.icon} height={"25px"} className="mx-4" />
							</a>
						</div>
					))}
				</div>
				<div className="d-flex justify-content-around mt-2 ms-0 legalLinks flex-wrap">
					<div>&copy;2024 COPYRIGHT</div>
					<div>PRESS</div>
					<div>TERMS OF USE</div>
					<div>PRIVACY POLICY</div>
					<div>COOKIES POLICY</div>
					<div>DO NOT SELL OR SHARE MY PERSONAL INFORMATION</div>
				</div>
			</Col>
		</Row>
	)
}
export default Footer;